
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
                <v-layout row wrap px-0 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.links.edit_path">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                    {{ item.title.toUpperCase() }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>
                </v-layout>
            <form @submit.prevent="updateLinks" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="links.link_name" type="text" :label="$store.getters.language.data.links.link_name" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="links.link_icon_dark" type="textarea" :label="$store.getters.language.data.links.link_icon_dark" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="links.link_icon_white" type="textarea" :label="$store.getters.language.data.links.link_icon_white" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex> -->
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="links.link_index" type="number" :label="$store.getters.language.data.links.link_index" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.links.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/links.request.js'
    export default {
        data() {
            return {
                links: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                
            }
        },
        computed: {
           
            page(){
                const pageKey = 'LINKS'
                return {}
            }
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneLinks()
        },
        methods: {
            getOneLinks() {
                this.loading = true
                requests.getOneLinks(this.id).then(r =>{
                    if (r.status == 200) {
                        this.links = r.data.links
                        
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Links',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Links',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateLinks() {
                this.loading = true
                requests.updateLinks(this.id,this.links).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'Links Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    